import React, { useState, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { ReviewsWrapper } from "./reviews.styles";
import Carousel from "../sliderCarousel/carousel.component";
import { useTranslation } from "react-i18next";

const libraries = ["places"];

const Reviews = ({ placeId, language = "en" }) => {
	const { t } = useTranslation();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC5dXduuOkqnd_nyiNZh9LwHnoGao5oN5E",
    libraries,
  });

  const [placeDetails, setPlaceDetails] = useState(null);

  const getPlaceDetails = (placeId) => {
    if (!placeId || !isLoaded) return;

    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    service.getDetails(
      {
        placeId,
        fields: [
          "name",
          "formatted_address",
          "rating",
          "reviews",
          "photos",
          "opening_hours",
          "website",
        ],
        language: language,
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPlaceDetails(place);
        } else {
          console.error("Error retrieving place details:", status);
        }
      }
    );
  };

  useEffect(() => {
    if (placeId && isLoaded) {
      getPlaceDetails(placeId);
    }
  }, [placeId, isLoaded, language]);

  if (loadError) return <div>Error loading Google Maps API</div>;
  if (!isLoaded) return <div>Loading...</div>;
console.log('placeDetails', placeDetails)
  return (
    <ReviewsWrapper>
      {placeDetails && (
        <div>
          {placeDetails.reviews && placeDetails.reviews.length > 0 ? (
            <Carousel placeDetails={placeDetails} autoplay={false} />
          ) : (
            <h2>{t("serviceDetailsPage.reviewsNo")}</h2>
          )}
        </div>
      )}
    </ReviewsWrapper>
  );
};

export default Reviews;

import styled from "styled-components";

export const CarouselContainer = styled.div`
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 0px 0px 20px 28px;
  box-sizing: border-box;
  position: relative;
	 @media (max-width: 1000px) {
  padding: 0px 0px 20px 15px;

	 }
  .placeRating {
	 @media (max-width: 1000px) {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding-right: 15px;
	 }
	.ratingData{
		display: flex;
		align-items: center;
		.reviewsData{
			margin-bottom: 8px;
		}
		.ratingInStar{
			display: flex;
			gap: 2px;
			/* margin-bottom: -15px; */
			svg{
				width: 7.5px;
			}
		}
		.reviewsNumb{
			margin-top: -9px;
color:#5B636D;
font-size: 12px;
font-weight: 500;
line-height: 16.39px;

		}
	}
    h1 {
      font-size: 21px;
      font-weight: 600;
      line-height: 28.69px;
      color: #1d2126;
      margin-right: 12px;
		display: flex;

		svg{
			margin-left: 2px;
			width: 65px;
		}
    }
    h2 {
      font-size: 24.32px;
      font-weight: 600;
      line-height: 38.91px;
		color: #1d2126;
      margin-right: 10px;
		    margin-block-start: 0px;
		    margin-block-end: 0px;
    }
    svg {
      height: 27px;
      width: 27px;
    }
  }
  .testimonial-top {
  }
  .carousel-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
	 	
  }
  .carouselHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .buttonsContainer {
    display: flex;
    margin-right: 28px;
    .arrow {
      border: none;
      background-color: transparent;
      padding: 12px;
      cursor: pointer;

      &.left-arrow {
      }

      &.right-arrow {
        transform: rotate(90);
      }
    }
    @media (max-width: 1000px) {
		display: none;
	 }
  }
  .carousel-track-wrapper {
    overflow: hidden;
    flex: 1;
    position: relative;
  }

  .carousel-track {
    display: flex;
    transition: transform 0.3s ease-in-out;
    will-change: transform;
	  @media (max-width: 1000px) {
			overflow-x: auto;
		 }
  }
  .testimonial-header {
    display: flex;
    align-items: center;
  }
  .testimonial-card {
    flex: 0 0 calc(100% / 3.1); /* Show 4 full items and 1 half */
    padding: 16px;
    height: 230px;
    box-sizing: border-box;
    background-color: #fff;
    margin: 0 5px 10px 5px;
	 border-radius: 8px;
    display: flex;
    flex-direction: column;
	box-shadow: 0px 3px 8px 0px #0000001F;
	margin-bottom: 10px;


    @media (max-width: 1000px) {
      flex: 0 0 calc(100% / 1.8); /* Show 4 full items and 1 half */
      margin-right: 15px;
    }
    @media (max-width: 768px) {
      flex: 0 0 calc(100% / 1.5); /* Show 4 full items and 1 half */
    }
    @media (max-width: 375px) {
      flex: 0 0 calc(100% / 1.2); /* Show 4 full items and 1 half */
    }
    .testimonial-rating {
      svg {
        width: 14.5px;
        height: 14.5px;
        margin-right: 1px;
      }
    }

    .testimonial-time {
      margin-left: 5px;
      font-size: 12px;
      font-weight: 600;
      line-height: 16.39px;
      color: #000000;
    }

    .testimonial-review {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.84px;
      height: 119px;
		margin-block-start: 0;
      overflow-y: auto;
    }

    .reviewer-info {
      display: flex;
      align-items: center;
      gap: 10px;
      .testimonial-image {
        width: 34px;
        height: 34px;
        object-fit: cover;
        border-radius: 50%;
      }
      .reviewer-stats {
      }
      .testimonial-name {
        font-size: 12px;
        font-weight: 600;
        line-height: 16.39px;
        color: #000000;
      }

      .reviewer-status {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.39px;
        color: #000000;
      }
    }
  }
`;

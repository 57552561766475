import React, { useContext, useEffect, useState } from 'react';
import { DropDownContainer, DropDownHeader, DropDownList, ListItem, DropDownWrapper, Arrow, Checkbox } from "./dropDown.styles";
import { useDropDown } from "../../contexts/dropDown.context";
import { ReactComponent as ArrowBottom } from "../../assets/img/arrowBottom.svg";
import { ReactComponent as Cancel } from "../../assets/img/cancel.svg";
import { ReactComponent as Mark } from "../../assets/img/check.svg";
import logoImg from "../../assets/img/logo-fixauto.png";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import servicesRo, { jsonDataRo } from '../Data/ServiceRo';
import servicesRu, { jsonDataRu } from '../Data/ServiceRu';
import servicesEn, { jsonDataEn } from '../Data/ServiceEn';
import { LanguageContext } from '../../contexts/language.context';

const DropDownFilters = () => {
  const { t, i18n } = useTranslation();
  const { selectedOptions, updateSelectedOption, clearSubOption,  } = useDropDown();
  const [openDropdown, setOpenDropdown] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();


const [jsonData, setJsonData] = useState({ orase: [], raioane: {} });
const [carBrands, setCarBrands] = useState([])



    const [services, setServices] = useState([]);
 const { selectedLanguage} = useContext(LanguageContext);
	useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const lang = pathSegments[1];
    
    if (lang === 'ro') {
      setServices(servicesRo);
		setJsonData(jsonDataRo)
		setCarBrands(["Toate tipurile de mașini"])
    } else if (lang === 'ru') {
		 setServices(servicesRu);
		 setJsonData(jsonDataRu)
		 setCarBrands(["Все типы авто"])
    } else {
      setServices(servicesEn);
		setJsonData(jsonDataEn)
		setCarBrands(["All types of cars"])
    }

  }, [location.pathname, selectedLanguage]);

  
  

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleCheckboxSelect = (dropdown, option) => {
    const currentLanguage = i18n.language;
    const currentPath = location.pathname; 
    const baseQuery = dropdown === 'regionOrCity' && Array.isArray(option) && option.length === 0
      ? ''
      : option;
    if (currentPath === `/${currentLanguage}/list`) {
      navigate(`/${currentLanguage}/list?page=1`, { replace: true });
    } else {
      navigate(`/${currentLanguage}/?page=1`, { replace: true });
    }

    if (dropdown === 'regionOrCity') {
      if (Array.isArray(option) && option.length === 0) {
        updateSelectedOption(dropdown, []);
        clearSubOption();
      } else {
        updateSelectedOption(dropdown, option);
      }
    } else {
      updateSelectedOption(dropdown, option);
    }
  };

  const getCombinedOptions = () => {
    const cityOptions = jsonData.orase.map(city => city.nume);
    const regionOptions = Object.keys(jsonData.raioane);
    return [...cityOptions, ...regionOptions];
  };

  const getSubOptions = (selectedOptions) => {
    let subOptions = [];
    selectedOptions.forEach(option => {
      if (jsonData.raioane[option]) {
        subOptions = [...subOptions, ...jsonData.raioane[option]];
      }
      const city = jsonData.orase.find(city => city.nume === option);
      if (city) {
        subOptions = [...subOptions, ...city.raioane];
      }
    });
    return subOptions;
  };

  const titlesSet = new Set();
  services.forEach(service => {
    service.serviceTypes.forEach(type => {
      titlesSet.add(type.title);
    });
  });

  const titles = Array.from(titlesSet);
//   const carBrands = ["Все типы авто"];
  const jobTypeOptions = titles;
  const combinedOptions = getCombinedOptions();
  const subOptions = getSubOptions(selectedOptions.regionOrCity || []);
  const carBrandOptions = carBrands;

  const isClearFilterVisible = (dropdown) => {
    if (dropdown === "regionOrCity") {
      return selectedOptions.regionOrCity.length > 0;
    } else if (dropdown === "subOption") {
      return selectedOptions.subOption.length > 0;
    } else if (dropdown === "jobType") {
      return selectedOptions.jobType.length > 0;
    } else if (dropdown === "carBrand") {
      return selectedOptions.carBrand.length > 0;
    }
    return false;
  };

  return (
    <DropDownWrapper>
      <DropDownContainer>
        <DropDownHeader className={isClearFilterVisible("jobType") ? 'firstElement thereIsSomething' : 'firstElement'} onClick={() => toggleDropdown("jobType")}>
          {t("homePage.dropDownFilters.jobType")}
          <Arrow open={openDropdown === "jobType"}>
            <ArrowBottom />
          </Arrow>
        </DropDownHeader>
        {openDropdown === "jobType" && (
          <DropDownList open={openDropdown === "jobType"}>
            <div className="resultButton">
              <button onClick={() => toggleDropdown("jobType")}>
                {t("homePage.dropDownFilters.seeResults")}
              </button>
            </div>
            <div className="dropDownMobileHeader">
              <img src={logoImg} alt="" />
              <div className="cancelButton" onClick={() => toggleDropdown("jobType")}>
                <Cancel />
              </div>
            </div>
            <div className="mobileTypeFilter">
              <h1>{t("homePage.dropDownFilters.jobType")}</h1>
              <ListItem className='removeFilter' style={{ opacity: isClearFilterVisible("jobType") ? 1 : 0.3 }} onClick={() => handleCheckboxSelect("jobType", [])}>
                {t("homePage.dropDownFilters.removeFilters")}
                <Cancel />
              </ListItem>
            </div>
            {jobTypeOptions.map((option, index) => (
              <ListItem className={selectedOptions.jobType.includes(option) ? "checked" : ""} key={index} onClick={() => handleCheckboxSelect("jobType", option)}>
                <div>{option}</div>
                <span className='mark'>
                  <Mark />
                </span>
                <Checkbox className='checkbox'
                  type="checkbox"
                  checked={selectedOptions.jobType.includes(option)}
                  onChange={() => handleCheckboxSelect("jobType", option)}
                />
              </ListItem>
            ))}
          </DropDownList>
        )}
      </DropDownContainer>

      <DropDownContainer>
        <DropDownHeader className={isClearFilterVisible("regionOrCity") ? 'thereIsSomething' : ''} onClick={() => toggleDropdown("regionOrCity")}>
          {t("homePage.dropDownFilters.region")}
          <Arrow open={openDropdown === "regionOrCity"}>
            <ArrowBottom />
          </Arrow>
        </DropDownHeader>
        {openDropdown === "regionOrCity" && (
          <DropDownList open={openDropdown === "regionOrCity"}>
            <div className="resultButton">
              <button onClick={() => toggleDropdown("regionOrCity")}>
                {t("homePage.dropDownFilters.seeResults")}
              </button>
            </div>
            <div className="dropDownMobileHeader">
              <img src={logoImg} alt="" />
              <div className="cancelButton" onClick={() => toggleDropdown("regionOrCity")}>
                <Cancel />
              </div>
            </div>
            <div className="mobileTypeFilter">
              <h1>{t("homePage.dropDownFilters.region")}</h1>
              <ListItem className='removeFilter' style={{ opacity: isClearFilterVisible("regionOrCity") ? 1 : 0.3 }} onClick={() => handleCheckboxSelect("regionOrCity", [])}>
                {t("homePage.dropDownFilters.removeFilters")}
                <Cancel />
              </ListItem>
            </div>
            {combinedOptions.map((option, index) => (
              <ListItem className={selectedOptions.regionOrCity.includes(option) ? "checked" : ""} key={index} onClick={() => handleCheckboxSelect("regionOrCity", option)}>
                <div>{option}</div>
                <span className='mark'>
                  <Mark />
                </span>
                <Checkbox
                  type="checkbox"
                  checked={selectedOptions.regionOrCity.includes(option)}
                  onChange={() => handleCheckboxSelect("regionOrCity", option)}
                />
              </ListItem>
            ))}
          </DropDownList>
        )}
      </DropDownContainer>

      <DropDownContainer>
        <DropDownHeader className={isClearFilterVisible("subOption") ? 'thereIsSomething' : ''} onClick={() => toggleDropdown("subOption")}>
          {t("homePage.dropDownFilters.sektor")}
          <Arrow open={openDropdown === "subOption"}>
            <ArrowBottom />
          </Arrow>
        </DropDownHeader>
        {openDropdown === "subOption" && selectedOptions.regionOrCity.length > 0 && (
          <DropDownList open={openDropdown === "subOption"}>
            <div className="resultButton">
              <button onClick={() => toggleDropdown("subOption")}>
                {t("homePage.dropDownFilters.seeResults")}
              </button>
            </div>
            <div className="dropDownMobileHeader">
              <img src={logoImg} alt="" />
              <div className="cancelButton" onClick={() => toggleDropdown("subOption")}>
                <Cancel />
              </div>
            </div>
            <div className="mobileTypeFilter">
              <h1>{t("homePage.dropDownFilters.sektor")}</h1>
              <ListItem className='removeFilter' style={{ opacity: isClearFilterVisible("subOption") ? 1 : 0.3 }} onClick={() => handleCheckboxSelect("subOption", [])}>
                {t("homePage.dropDownFilters.removeFilters")}
                <Cancel />
              </ListItem>
            </div>
            {subOptions.map((option, index) => (
              <ListItem className={selectedOptions.subOption.includes(option) ? "checked" : ""} key={index} onClick={() => handleCheckboxSelect("subOption", option)}>
                <div>{option}</div>
                <span className='mark'>
                  <Mark />
                </span>
                <Checkbox
                  type="checkbox"
                  checked={selectedOptions.subOption.includes(option)}
                  onChange={() => handleCheckboxSelect("subOption", option)}
                />
              </ListItem>
            ))}
          </DropDownList>
        )}
      </DropDownContainer>

      <DropDownContainer>
        <DropDownHeader className={isClearFilterVisible("carBrand") ? 'lastElement thereIsSomething' : 'lastElement'} onClick={() => toggleDropdown("carBrand")}>
          {t("homePage.dropDownFilters.autoType")}
          <Arrow open={openDropdown === "carBrand"}>
            <ArrowBottom />
          </Arrow>
        </DropDownHeader>
        {openDropdown === "carBrand" && (
          <DropDownList open={openDropdown === "carBrand"}>
            <div className="resultButton">
              <button onClick={() => toggleDropdown("carBrand")}>
                {t("homePage.dropDownFilters.seeResults")}
              </button>
            </div>
            <div className="dropDownMobileHeader">
              <img src={logoImg} alt="" />
              <div className="cancelButton" onClick={() => toggleDropdown("carBrand")}>
                <Cancel />
              </div>
            </div>
            <div className="mobileTypeFilter">
              <h1>{t("homePage.dropDownFilters.autoType")}</h1>
              <ListItem className='removeFilter' style={{ opacity: isClearFilterVisible("carBrand") ? 1 : 0.3 }} onClick={() => handleCheckboxSelect("carBrand", [])}>
                {t("homePage.dropDownFilters.removeFilters")}
                <Cancel />
              </ListItem>
            </div>
            {carBrandOptions.map((option, index) => (
              <ListItem className={selectedOptions.carBrand.includes(option) ? "checked" : ""} key={index} onClick={() => handleCheckboxSelect("carBrand", option)}>
                <div>{option}</div>
                <span className='mark'>
                  <Mark />
                </span>
                <Checkbox
                  type="checkbox"
                  checked={selectedOptions.carBrand.includes(option)}
                  onChange={() => handleCheckboxSelect("carBrand", option)}
                />
              </ListItem>
            ))}
          </DropDownList>
        )}
      </DropDownContainer>
    </DropDownWrapper>
  );
};

export default DropDownFilters;
